<template>
  <div>
    <div>
      <v-card-title>
        <h1>القوائم الغير مسددة ( {{ type }} )</h1>
        <v-spacer></v-spacer>
        <v-btn @click="openPrint" x-large color="primary">
          <v-icon>mdi-printer</v-icon>
          طباعة
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="3" style="padding-bottom: 0">
          <label for="se">Search Order NO.</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers()"
            :items="reports"
            :loading="loading"
            hide-default-footer
            :no-data-text="$t('no-data')"
            class="mt-2"
          >
            <template v-slot:item.created="{ item }">
              {{ $service.formatDate(item.created, false) }}
            </template>
            <template v-slot:item.index="{ index }">
              {{ index + 1 }}
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="printDialog"
      persistent
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card flat outlined>
        <v-card-title>
          Print
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="printDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular indeterminate size="50" />
          </div>
          <Print
            v-else
            :products="secReports"
            id="printDiv"
            :filtered="false"
            :prefix="prefix"
            :options="options"
          />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions dir="ltr">
          <!-- <v-spacer></v-spacer> -->
          <div dir="ltr">
            <v-btn v-print="printObj" class="mx-2" large color="primary">
              Print
              <v-icon class="mx-1">mdi-printer</v-icon>
            </v-btn>
            <v-btn large color="error" outlined @click="printDialog = false">
              Cancel
              <v-icon class="mx-1">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Print from "./Print.vue";
export default {
  components: { Print },
  data() {
    return {
      printObj: {
        id: "#printDiv",
        preview: false,
        previewTitle: "Test Title", // The title of the preview window. The default is 打印预览
        extraCss:
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
      },
      printDialog: false,
      reports: [],
      loading: false,
      fakeDialog: false,
      fakeMessage: "",
      secReports: [],

      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
        Sort: "asc",
        FullObtained: null,
      },
      total: 0,
      type: "Pharmacy",
      prefix: "",
    };
  },
  methods: {
    getOffice() {
      this.$http
        .get("/Offices/" + this.$store.state.userInfo.office.id)
        .then((res) => {
          this.office = res.data.result;
        });
    },
    headers() {
      let prefix = this.type + "Order.";
      let name = prefix + "pharmacy.fullName";
      if (this.type == "pharmacy") {
        name = prefix + "pharmacy.fullName";
      } else if (this.type == "repository") {
        name = prefix + "repository.fullName";
      } else if (this.type == "doctor") {
        name = prefix + "doctor.fullName";
      }

      let headers = [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: prefix + "orderNO",
        },
        { text: this.$t('created-by'), value: prefix + "userCreatedFullName" },
        {
          text: this.type,
          value: name,
        },
        { text: "Received", value: prefix + "amountReceived" },
        { text: this.$t('price'), value: prefix + "totalPrice" },
      ];

      headers.push({ text: "Date", value: "created" });
      return headers;
    },
    async openPrint() {
      try {
        this.loading = true;
        let type = "";
        if (this.type != "pharmacy") {
          type = this.type;
        }
        this.secReports = [];
        let filter = { ...this.options };
        if(filter.Search == ""){
          delete filter.Search;
        }
        filter.PageSize = this.total;
        let prod = await this.$http.get("/" + type + "OrdersForwarded", {
          params: filter,
        });
        this.secReports = [];
        this.secReports = prod.data.data;
        this.$forceUpdate();
        this.loading = false;
        this.printDialog = true;
      } catch (e) {
        console.log(e);
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
        this.loading = false;
      }
    },
    getReports(isSecond = false) {
      this.loading = true;
      let page = this.options.PageSize;
      if (isSecond) {
        this.options.PageSize = this.total;
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      let type = "";
      if (this.type != "pharmacy") {
        type = this.type;
      }
      this.$http
        .get("/" + type + "OrdersForwarded" + filter.replace("?&", "?"))
        .then((prod) => {
          this.total = prod.data.count;
          this.reports = [];
          this.reports = prod.data.data;
          this.$forceUpdate();
          this.options.PageSize = page;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    getRegions(search = "") {
      this.loading = true;
      let params = {};
      if (search) {
        params.search = search;
      }
      this.$http.get(`/regions`, { params }).then((res) => {
        this.regions = res.data.result.data;
        this.loading = false;
      });
    },
  },
  created() {
    this.type = this.$route.params.type;
    this.prefix = this.$route.params.type.toLowerCase();
    this.getReports(false);
    this.getRegions();
    // this.searchPharmacy();
    this.$http.get("/Pharmacies").then((res) => {
      this.pharmacies = res.data.data;
      this.loading = false;
    });
  },
  watch: {
    options: {
      handler() {
        this.getReports(false);
      },
      deep: true,
    },
    printDialog() {
      // this.getReports(true);
    },
    searchRegion(val) {
      this.getRegions(val);
    },
    searchPharmacy() {
      if (this.searchPharmacy != "" && this.searchPharmacy != null) {
        this.pharmacies = [];
        this.loading = true;
        this.$http
          .get("/Pharmacies?Search=" + this.searchPharmacy)
          .then((res) => {
            this.pharmacies = res.data.data;
            this.loading = false;
          });
      }
    },
    searchDoctor() {
      if (this.searchDoctor != "" && this.searchDoctor != null) {
        this.doctors = [];
        this.loading = true;
        this.$http
          .get("/Doctors?Search=" + this.searchDoctor, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.doctors = res.data.data;
            this.loading = false;
          });
      }
    },
    searchRepository() {
      if (this.searchRepository != "" && this.searchRepository != null) {
        this.repositories = [];
        this.loading = true;
        this.$http
          .get("/Repositories?Search=" + this.searchRepository, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.repositories = res.data.data;
            this.loading = false;
          });
      }
    },
    $route(to) {
      this.type = to.params.type;
      this.prefix = to.params.type.toLowerCase();
      this.getReports(false);
    },
  },
};
</script>
