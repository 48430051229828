<template>
<v-navigation-drawer v-model="$store.state.drawer" bottom floating width="300" :right="$root.$i18n.locale == 'ar'" app :dark="$store.state.style.drawer == 'dark'" style="border: 0px !important">
    <v-divider></v-divider>
    <!-- <perfect-scrollbar> -->
        <v-list class="py-5">
            <v-list-item>
                <v-list-item-title>
                    <v-img src="../assets/scode field.png" alt="" contain height="75" />
                </v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item>
                <v-list-item-title class="font-weight-bold">
                    <p class="d-flex justify-center align-content-center flex-column text-center">
                        <v-img :src="require(`@/assets/svg/${icon}.png`)" contain cover height="100" class="mb-2" />
                        {{ title }}
                        <small class="v-card__subtitle text-h5" style="margin: 0; padding: 0">{{ subtitle }}</small>
                    </p>
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav>
            <!-- <perfect-scrollbar> -->
                <template v-for="item in items">
                    <template v-if="hasAccess(item)">
                        <v-list-item :key="item.title" v-if="item.children === undefined" active-class="grad" link :to="
                  typeof item.route === 'string'
                    ? { name: item.route }
                    : item.route
                ">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group v-model="item.active" :key="item.title" no-action v-else>
                            <template v-slot:activator>
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item v-for="child in item.children" :key="child.title" link :to="
                    typeof child.route === 'string'
                      ? { name: child.route }
                      : child.route
                  ">
                                <v-list-item-content>
                                    <v-list-item-title>{{
                      child.title
                    }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </template>
            <!-- </perfect-scrollbar> -->
        </v-list>
    <!-- </perfect-scrollbar> -->
</v-navigation-drawer>
</template>

<script>
export default {
    // props: ["title", "icon", "items"]?,
    methods: {
        hasAccess(item) {
            return item.access == undefined ? true : item.access;
        },
    },
    props: {
        title: {
            type: String,
            default: "Drawer",
        },
        icon: {
            type: String,
            default: "menu",
        },
        items: {
            type: Array,
            default: () => [{
                title: "Home",
                route: "home",
            }, ],
        },
        subtitle: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped></style>
