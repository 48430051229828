<template>
  <div>
    <div>
      <v-card-title
        ><h1>Payment overdue</h1>
        <v-spacer></v-spacer>
        <v-btn @click="printDialog = !printDialog" x-large color="primary">
          <v-icon>mdi-printer</v-icon>
          Print
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="3" style="padding-bottom: 0">
           <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
disable-pagination
            :headers="headers()"
            :items="reports"
            :loading="loading"
            hide-default-footer
            :no-data-text="$t('no-data')"
            class="mt-2"
          >
            <template v-slot:item.lasMoveDate="{ item }">
              {{ $service.formatDate(item.lasMoveDate, false) }}
            </template>
            <template v-slot:item.index="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.fullName="{ item }">
              <span v-if="item.pharmacy">
                {{ item.pharmacy.fullName }}
              </span>
              <span v-if="item.doctor">
                {{ item.doctor.fullName }}
              </span>
              <span v-if="item.repository">
                {{ item.repository.fullName }}
              </span>
            </template>
          </v-data-table>
          <!-- <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div> -->
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="printDialog"
      scrollable
      persistent
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card flat outlined>
        <v-card-title>
          Print
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="printDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <Print
            :office="office"
            :reports="reports"
            id="printDiv"
            :prefix="prefix"
          />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions dir="ltr">
          <!-- <v-spacer></v-spacer> -->
          <div dir="ltr">
            <v-btn v-print="printObj" class="mx-2" large color="primary">
              Print
              <v-icon class="mx-1">mdi-printer</v-icon>
            </v-btn>
            <v-btn large color="error" outlined @click="printDialog = false">
              Cancel
              <v-icon class="mx-1">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Print from "./Print.vue";
export default {
  components: { Print },
  watch: {
    options: {
      handler() {
        this.getReports();
      },
      deep: true,
    },
  },
  data() {
    return {
      reports: [],
      office: {},
      loading: false,
      printObj: {
        id: "#printDiv",
        preview: false,
        previewTitle: "Test Title", // The title of the preview window. The default is 打印预览
        extraCss:
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
      },
      printDialog: false,
      fakeDialog: false,
      fakeMessage: "",
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
        Debt: true,
        Receipt: true,
      },
      total: 0,
    };
  },
  methods: {
    getOffice(){
        this.$http
        .get("/Offices/" )
        .then((res) => {
          this.office = res.data.result;
        })
      },
    headers() {
      // let prefix = "pharmacy";
      return [
        {
          text: "No.",
          value: "index",
        },
        {
          text: "Date",
          value: "lasMoveDate",
        },
        {
          text: "Customer",
          value: "fullName",
        },
      ];
    },
    getReports() {
      this.loading = true;
      this.getOffice();
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get("/Reports/clientsOverduePayment" + filter.replace("?&", "?"))
        .then((prod) => {
          //   this.total = prod.data.count;
          //   let temp = []

          this.reports = [
            ...prod.data.result.repositories,
            ...prod.data.result.pharmacies,
            ...prod.data.result.doctors,
          ];
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  created() {
    this.getOffice();
    this.getReports();
  },
};
</script>
