<template>
<div>
    <div>
        <v-card-title>
            <h1>كشف حساب ( {{ type }} )</h1>
            <v-spacer></v-spacer>
            <v-btn @click="openPrint" x-large color="primary">
                <v-icon>mdi-printer</v-icon>
                طباعة
            </v-btn>
        </v-card-title>
        <v-row>
            <!-- <v-col cols="3" style="padding-bottom: 0">
           <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col> -->
            <v-col cols="3">
                <label for="se">Select {{ type }} </label>
                <v-autocomplete v-if="type == 'pharmacy'" clearable v-model="options.pharmacyId" :items="pharmacies" :search-input.sync="searchPharmacy" :loading="loading" :no-data-text="
              searchPharmacy == '' && searchPharmacy == null
                ? 'Write to search ...'
                : 'No data..'
            " solo filled class="mt-2" item-value="id" chips item-text="fullName" :label="'Select ' + type" hide-details="auto">
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.status != null">
                                {{ status(data.item.status) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>

                <v-autocomplete v-else-if="type == 'repository'" clearable v-model="options.repositoryId" :items="repositories" :search-input.sync="searchRepository" :loading="loading" :no-data-text="
              searchRepository == '' && searchRepository == null
                ? 'Write to search ...'
                : 'No data..'
            " solo filleds class="mt-2" item-value="id" chips item-text="fullName" :label="'Select ' + type" hide-details="auto">
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.status != null">
                                {{ status(data.item.status) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>

                <v-autocomplete v-else hide-detials="auto" clearable chips v-model="options.doctorId" :items="doctors" :search-input.sync="searchDoctor" :loading="loading" :no-data-text="
              searchDoctor == '' && searchDoctor == null
                ? 'Write to search ...'
                : 'No data..'
            " solo filleds class="mt-2" item-value="id" :label="'Select ' + type" item-text="fullName" hide-details="auto" />
            </v-col>
            <v-col cols="3">
                <label for="se">ٌRegion</label>
                <v-autocomplete v-model="options.regionId" :label="$t('region')" hide-details="auto" chips filled solo class="mt-2" :items="regions" item-text="name" item-value="id" :search-input.sync="searchRegion"></v-autocomplete>
            </v-col>
            <v-col cols="3">
                <label for="se">Start Date</label>
                <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" class="white" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field hide-detials="auto" solo filled class="mt-2" v-model="options.startDate" label="Start Date" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="options.startDate">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="
                  () => {
                    options.startDate = '';
                  }
                ">
                            Reset
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <label for="se">End Date</label>
                <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" class="white" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field hide-detials="auto" solo filled class="mt-2" v-model="options.endDate" label="End Date" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="options.endDate">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="
                  () => {
                    options.endDate = '';
                  }
                ">
                            Reset
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <label for="se">Sales Representative</label>
                <v-autocomplete filled solo class="mt-2" label="Sales Representative" :no-data-text="$t('write-name-to-search')" :items="salesRepresentatives" item-text="fullName" hide-details="auto" v-model="options.receivingUserId" :search-input.sync="searchSalesRepresentative" item-value="id" :loading="loading" clearable>
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                            {{ data.item.fullName }}
                            <small class="mx-1">{{ data.item.userTypeName }}</small>
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.userTypeName"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <label for="se">Medical Representative</label>
                <v-autocomplete filled solo class="mt-2" label="Medical Representative" :no-data-text="$t('write-name-to-search')" :items="medicalRepresentatives" item-text="fullName" hide-details="auto" v-model="options.UserCreatedId" :search-input.sync="searchMedicalRepresentative" item-value="id" :loading="loading" clearable>
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                            {{ data.item.fullName }}
                            <small class="mx-1">{{ data.item.userTypeName }}</small>
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.userTypeName"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <label>Product</label>
                <v-autocomplete @input="getReports(false)" v-model="options.productId" :search-input.sync="filter.searchProduct" v-debounce:500ms="getProducts" clearable item-text="tradeName" item-value="id" :items="productsList" placeholder="Product" :loading="loading" hide-details="auto" filled solo class="mt-2" label="Product" />
            </v-col>
            <v-col cols="12">
                <download-excel :fetch="getReports" :fields="fields">
                    <v-btn color="primary">
                        <v-icon>mdi-download</v-icon>
                        Download Excel
                    </v-btn>
                </download-excel>
            </v-col>
            <v-col cols="12">
                <v-data-table disable-pagination :headers="headers()" :items="products" :loading="loading" hide-default-footer :no-data-text="$t('no-data')" class="mt-2">
                    <template v-slot:item.created="{ item }">
                        {{ $service.formatDate(item.created, false) }}
                    </template>
                    <template v-slot:item.type="{ item }">
                        <span v-if="item.debt"> بيع </span>
                        <span v-else> قبض </span>
                    </template>
                    <template v-slot:item.index="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.receiptAmount="{ item }">
                        {{item.receipt == true ? $service.formatCurrency(item.receiptAmount) : 0}}
                    </template>
                    <template v-slot:item.pharmacyOrder.totalPriceAfterDiscount="{ item }">
                        {{item.receipt == false ? $service.formatCurrency(item.pharmacyOrder.totalPriceAfterDiscount) : 0}}
                    </template>
                    <template v-slot:item.doctorOrder.totalPriceAfterDiscount="{ item }">
                        {{item.receipt == false ? $service.formatCurrency(item.doctorOrder.totalPriceAfterDiscount) : 0}}
                    </template>
                    <template v-slot:item.repositoryOrder.totalPriceAfterDiscount="{ item }">
                        {{item.receipt == false ? $service.formatCurrency(item.repositoryOrder.totalPriceAfterDiscount) : 0}}
                    </template>
                    <template v-slot:item.product.price="{ item }">
                        {{ $service.formatCurrency(item.product.price) }}
                    </template>
                </v-data-table>
                <div class="d-flex justify-center">
                    <v-pagination v-model="options.PageIndex" :length="parseInt(total / options.PageSize) + 1" />
                </div>
            </v-col>
        </v-row>
    </div>
    <v-dialog v-model="printDialog" persistent :overlay="false" max-width="1800px" transition="dialog-transition">
        <v-card flat outlined>
            <v-card-title>
                Print
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="printDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-container fluid>
                <div v-if="loading" class="d-flex justify-center">
                    <v-progress-circular indeterminate size="50" />
                </div>
                <Print v-else :products="secProducts" id="printDiv" :filtered="
              options.pharmacyId == null &&
              options.repositoryId == null &&
              options.doctorId == null
            " :prefix="prefix" :options="options" />
            </v-container>
            <v-divider></v-divider>
            <v-card-actions dir="ltr">
                <!-- <v-spacer></v-spacer> -->
                <div dir="ltr">
                    <v-btn v-print="printObj" class="mx-2" large color="primary">
                        Print
                        <v-icon class="mx-1">mdi-printer</v-icon>
                    </v-btn>
                    <v-btn large color="error" outlined @click="printDialog = false">
                        Cancel
                        <v-icon class="mx-1">mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Print from "./Print.vue";
export default {
    components: {
        Print
    },
    data() {
        return {
            fields: {
                "No.": "orderNO",
                "Order Date": {
                    key: "created",
                    callback: (v) => {
                        return this.$service.formatDate(new Date(v.created), false);
                    },
                },
                Pharmacy: "pharmacy.fullName",
                "Total Price": "totalPrice",
                "Total Price After Discount": "totalPriceAfterDiscount",
                discount: "discount",
                "Total Number Of Unit": "totalNumberOfUnit",
                "Total Number Of Unit Free": "totalNumberOfUnitFree",
                Status: {
                    key: "status",
                    callback: (v) => {
                        return this.backStatus.find((v2) => v.status == v2.key) != null ?
                            this.backStatus.find((v2) => v.status == v2.key).name :
                            "";
                    },
                },
                "Created By": "userCreatedFullName",
            },
            printObj: {
                id: "#printDiv",
                preview: false,
                previewTitle: "Test Title", // The title of the preview window. The default is 打印预览
                extraCss: "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
            },
            printDialog: false,
            reports: [],
            loading: false,
            fakeDialog: false,
            fakeMessage: "",
            products: [],
            secProducts: [],
            searchRegion: "",
            regions: [],

            options: {
                regionId: null,
                Search: "",
                pharmacyId: null,
                repositoryId: null,
                doctorId: null,
                productId: null,
                PageIndex: 1,
                startDate: "",
                endDate: "",
                PageSize: 50,
                Sort: "desc",
                receivingUserId: "",
                UserCreatedId: "",
            },
            total: 0,
            type: "Pharmacy",
            prefix: "",
            pharmacies: [],
            repositories: [],
            doctors: [],
            searchPharmacy: "",
            searchRepository: "",
            searchDoctor: "",
            salesRepresentatives: [],
            searchSalesRepresentative: "",

            medicalRepresentatives: [],
            searchMedicalRepresentative: "",
            filter: {
                searchProduct: null,
                productId: null
            },
            productsList: []
        };
    },
    methods: {
        async getProducts() {
            this.loading = true
            try {
                const res = await this.$http.get(`Products?PageIndex=1&PageSize=100&Search=${this.filter.searchProduct == null ? '' : this.filter.searchProduct}`)
                console.log('products')
                console.log(res.data.data)
                this.productsList = res.data.data

            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        async searchRepresentative(search = null, type = "sales") {
            this.loading = true;
            let typeId = "a84e6408-4601-48d5-8d72-2d39b00d9833";
            if (type == "medical") {
                typeId = "69ae7d50-845a-4d3d-8ac7-1fa35599ad5b";
            }
            let filter = {
                Search: search,
                RepresentativeTypeId: typeId,
            };
            if (search == null || search == "") {
                filter = {
                    RepresentativeTypeId: typeId,
                };
            }
            let response = await this.$http.get("/Representatives", {
                params: filter,
            });
            return response.data.data;
        },
        getOffice() {
            this.$http
                .get("/Offices/" + this.$store.state.userInfo.office.id)
                .then((res) => {
                    this.office = res.data.result;
                });
        },
        headers() {
            return [{
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Date",
                    value: "created",
                    sort: true,
                },
                {
                    text: "Product",
                    value: "product.product.tradeName",
                },
                {
                    text: "Quantity",
                    value: "product.qty",
                },
                {
                    text: "Bonus",
                    value: "product.bonus",
                },
                {
                    text: "Unit Price",
                    value: "product.price",
                },
                {
                    text: "Unit Discount",
                    value: "product.discount",
                },
                {
                    text: "Cash Discount",
                    value: "cashDiscount",
                },
                // {
                //   text: "Amount Received",
                //   value: "receiptAmount",
                // },
                // {
                //   text: "Net Amount",
                //   value: `${this.prefix}Order.totalPriceAfterDiscount`,
                // },
                {
                    text: "Order No.",
                    value: `${this.prefix}Order.orderNO`,
                },
                {
                    text: this.$t('name'),
                    value: `${this.prefix}Order.${this.prefix}.fullName`,
                },
            ];
        },
        status(stat) {
            var ret = "Select";
            Object.keys(stat).forEach((key) => {
                if (stat[key] == true) {
                    //
                    ret = key;
                }
            });
            return ret.replace("is", "");
        },
        async openPrint() {
            try {
                let filters = {
                    ...this.options
                };
                this.loading = true;
                filters.PageSize = this.total;
                let prod = await this.$http.get("/" + this.type + "FinancialDues", {
                    params: filters,
                });
                this.total = prod.data.result.count;
                let reports = prod.data.result.data;
                this.secProducts = [];

                reports.forEach((item) => {
                    item[`${this.prefix + "Order"}`].products.forEach((product) => {
                        this.secProducts.push({
                            ...item,
                            product
                        });
                    });
                });
                this.secProducts.sort(function (a, b) {
                    return new Date(a.created) - new Date(b.created);
                });
                this.$forceUpdate();
                this.loading = false;
                this.printDialog = true;
            } catch (e) {
                console.log(e);

                this.loading = false;
                this.$store.commit("UPDATE_SNACKBAR", true);
                this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
            }
        },
        getReports(isSecond = false) {
            this.loading = true;
            let page = this.options.PageSize;
            if (isSecond) {
                this.options.PageSize = this.total;
            }
            let filter =
                "?" +
                Object.entries(this.options)
                .map(([key, val]) => {
                    if (val !== "" && val !== null) {
                        return `${key}=${val}`;
                    }
                })
                .join("&");

            this.$http
                .get("/" + this.type + "FinancialDues" + filter.replace("?&", "?"))
                .then((prod) => {
                    this.total = prod.data.result.count;
                    this.reports = prod.data.result.data;
                    this.products = [];

                    this.reports.forEach((item) => {
                        item[`${this.prefix + "Order"}`].products.forEach((product) => {
                            this.products.push({
                                ...item,
                                product
                            });
                        });
                    });
                    this.products.sort(function (a, b) {
                        return new Date(a.created) - new Date(b.created);
                    });
                    this.$forceUpdate();
                    this.options.PageSize = page;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e);
                    this.$store.commit("UPDATE_SNACKBAR", true);
                    this.$store.commit(
                        "UPDATE_SNACKBAR_MESSAGE",
                        e.response.data.message
                    );
                });
        },
        getRegions(search = "") {
            this.loading = true;
            let params = {};
            if (search) {
                params.search = search;
            }
            this.$http.get(`/regions`, {
                params
            }).then((res) => {
                this.regions = res.data.result.data;
                this.loading = false;
            });
        },
    },
    async created() {
        this.type = this.$route.params.type;
        this.prefix = this.$route.params.type.toLowerCase();
        this.getReports(false);
        this.getRegions();
        this.getProducts();
        this.salesRepresentatives = await this.searchRepresentative(null, "sales");
        this.medicalRepresentatives = await this.searchRepresentative(
            null,
            "medical"
        );
        this.$http.get("/Pharmacies").then((res) => {
            this.pharmacies = res.data.data;
            this.loading = false;
        });
    },
    watch: {
        options: {
            handler() {
                this.getReports(false);
            },
            deep: true,
        },
        async searchSalesRepresentative(val) {
            this.salesRepresentatives = await this.searchRepresentative(val, "sales");
        },
        async searchMedicalRepresentative(val) {
            this.medicalRepresentatives = await this.searchRepresentative(
                val,
                "medical"
            );
        },
        printDialog() {
            // this.getReports(true);
        },
        searchRegion(val) {
            this.getRegions(val);
        },
        searchPharmacy() {
            if (this.searchPharmacy != "" && this.searchPharmacy != null) {
                this.pharmacies = [];
                this.loading = true;
                this.$http
                    .get("/Pharmacies?Search=" + this.searchPharmacy)
                    .then((res) => {
                        this.pharmacies = res.data.data;
                        this.loading = false;
                    });
            }
        },
        searchDoctor() {
            if (this.searchDoctor != "" && this.searchDoctor != null) {
                this.doctors = [];
                this.loading = true;
                this.$http
                    .get("/Doctors?Search=" + this.searchDoctor, {
                        params: {
                            OfficeId: this.$store.state.userInfo.office.id,
                            CompanyId: this.$store.state.userInfo.userData.companyId,
                        },
                    })
                    .then((res) => {
                        this.doctors = res.data.data;
                        this.loading = false;
                    });
            }
        },
        searchRepository() {
            if (this.searchRepository != "" && this.searchRepository != null) {
                this.repositories = [];
                this.loading = true;
                // this.$http
                //     .get("/Repositories?Search=" + this.searchRepository, {
                //         params: {
                //             OfficeId: this.$store.state.userInfo.office.id,
                //             CompanyId: this.$store.state.userInfo.userData.companyId,
                //         },
                //     })
                this.$http.get("/Repositories?Search=" + this.searchRepository)
                    .then((res) => {
                        this.repositories = res.data.data;
                        this.loading = false;
                    });
            }
        },
        $route(to) {
            this.type = to.params.type;
            this.prefix = to.params.type.toLowerCase();
            this.getReports(false);
        },
    },
};
</script>
