<template>
  <div>
    <div>
      <v-card-title>
        <h1>مبيعات القطع ( {{ type }} )</h1>
        <v-spacer></v-spacer>
        <v-btn @click="printDialog = !printDialog" x-large color="primary">
          <v-icon>mdi-printer</v-icon>
          طباعة
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="3">
          <label for="se">From Date </label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            label="From"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.startDate"
            append-icon="mdi-magnify"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <label for="se">To Date </label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            label="To"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.endDate"
            append-icon="mdi-magnify"
            type="date"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="3">
          <label for="se">Search Product </label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col> -->

        <v-col cols="3">
          <label for="">Select Product</label>
          <v-autocomplete
            v-model="options.ProductId"
            solo
            filled
            class="mt-2"
            :items="products"
            :search-input.sync="searchProduct"
            :loading="loading"
            :no-data-text="
              searchProduct == '' && searchProduct == null
                ? 'Write to search ...'
                : 'No data..'
            "
            item-text="tradeName"
            item-value="id"
            hide-details="auto"
            chips
          >
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                {{ data.item.tradeName }}
                <small class="mx-1">{{ data.item.scientificName }}</small>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.tradeName }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.scientificName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <label for="se">Select {{ type }} </label>
          <v-autocomplete
            v-if="type == 'pharmacy'"
            clearable
            v-model="options.pharmacyId"
            :items="pharmacies"
            :search-input.sync="searchPharmacy"
            :loading="loading"
            :no-data-text="
              searchPharmacy == '' && searchPharmacy == null
                ? 'Write to search ...'
                : 'No data..'
            "
            solo
            filleds
            class="mt-2"
            item-value="id"
            chips
            item-text="fullName"
            :label="'Select ' + type"
            hide-details="auto"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.fullName"
                ></v-list-item-title>
                <v-list-item-subtitle v-if="data.item.status != null">
                  {{ status(data.item.status) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else-if="type == 'repository'"
            clearable
            v-model="options.repositoryId"
            :items="repositories"
            :search-input.sync="searchRepository"
            :loading="loading"
            :no-data-text="
              searchRepository == '' && searchRepository == null
                ? 'Write to search ...'
                : 'No data..'
            "
            solo
            filleds
            class="mt-2"
            item-value="id"
            chips
            item-text="fullName"
            :label="'Select ' + type"
            hide-details="auto"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.fullName"
                ></v-list-item-title>
                <v-list-item-subtitle v-if="data.item.status != null">
                  {{ status(data.item.status) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else
            hide-detials="auto"
            clearable
            chips
            v-model="options.doctorId"
            :items="doctors"
            :search-input.sync="searchDoctor"
            :loading="loading"
            :no-data-text="
              searchDoctor == '' && searchDoctor == null
                ? 'Write to search ...'
                : 'No data..'
            "
            solo
            filleds
            class="mt-2"
            item-value="id"
            :label="'Select ' + type"
            item-text="fullName"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers()"
            :items="reports"
            :loading="loading"
            hide-default-footer
            :no-data-text="$t('no-data')"
            class="mt-2"
          >
            <template v-slot:item.lasMoveDate="{ item }">
              {{ $service.formatDate(item.lasMoveDate, false) }}
            </template>
            <template v-slot:item.index="{ index }">
              {{ index + 1 }}
            </template>
          </v-data-table>
          <!-- <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div> -->
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="printDialog"
      persistent
      :overlay="false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card flat outlined>
        <v-card-title>
          Print
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="printDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <Print
            :products="reports"
            id="printDiv"
            :filtered="
              options.pharmacyId == null &&
                options.repositoryId == null &&
                options.doctorId == null
            "
            :prefix="prefix"
          />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions dir="ltr">
          <!-- <v-spacer></v-spacer> -->
          <div dir="ltr">
            <v-btn v-print="printObj" class="mx-2" large color="primary">
              Print
              <v-icon class="mx-1">mdi-printer</v-icon>
            </v-btn>
            <v-btn large color="error" outlined @click="printDialog = false">
              Cancel
              <v-icon class="mx-1">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Print from "./Print.vue";
export default {
  components: { Print },
  data() {
    return {
      printObj: {
        id: "#printDiv",
        preview: false,
        previewTitle: "Test Title", // The title of the preview window. The default is 打印预览
        extraCss:
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
      },
      printDialog: false,
      reports: [],
      loading: false,
      fakeDialog: false,
      fakeMessage: "",
      searchProduct: "",
      products: [],
      options: {
        Search: null,
        pharmacyId: null,
        repositoryId: null,
        doctorId: null,
        PageIndex: 1,
        PageSize: 1000,
        startDate:null,
        endDate:null
      },
      total: 0,
      type: "Pharmacy",
      prefix: "",
      pharmacies: [],
      repositories: [],
      doctors: [],
      searchPharmacy: "",
      searchRepository: "",
      searchDoctor: "",
    };
  },
  methods: {
    getOffice() {
      // this.$http
      //   .get("/Offices/" + this.$store.state.userInfo.office.id)
      //   .then((res) => {
      //     this.office = res.data.result;
      //   });
    },
    headers() {
      return [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "index",
        },
        {
          text: this.prefix,
          value: this.prefix + ".fullName",
        },
        {
          text: "Product",
          value: "product.tradeName",
        },
        {
          text: "Sold No.",
          value: "qty",
        },
        {
          text: "Bonus",
          value: "bonus",
        },
      ];
    },
    status(stat) {
      var ret = "Select";
      Object.keys(stat).forEach((key) => {
        if (stat[key] == true) {
          //
          ret = key;
        }
      });
      return ret.replace("is", "");
    },
    getReports() {
      this.loading = true;
      this.getOffice();
      
      var filterLink = "";

      for(var key in this.options){
        if(this.options[key] !== null){
          filterLink += `${key}=${this.options[key]}&`
        }
      }

      console.log("filterLink.slice(0,filterLink.length - 1)")
      filterLink.slice(0,filterLink.length - 1)
      
      // let filter = 
      //   "?" +
        
      //   Object.entries(this.options)
      //     .map(([key, val]) => {
      //       if (val !== "" && val !== null) {
      //         return `${key}=${val}`;
      //       }
      //     })
      //     .join("&");
      this.$http
        .get(
          `/reports/${this.type}/productsSales?${filterLink}`
        )
        .then((prod) => {
          // this.total = prod.data.count;
          this.reports = prod.data.result;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  created() {
    // this.searchPharmacy();
    this.type = this.$route.params.type;
    this.prefix = this.$route.params.type.toLowerCase();
    this.$http.get("/Products?Search=" + this.searchProduct).then((res) => {
      this.products = res.data.data;
      this.loading = false;
    });
    this.getReports();
    
  },
  watch: {
    options: {
      handler() {
        this.getReports();
      },
      deep: true,
    },

    searchPharmacy() {
      // if (this.searchPharmacy != "" && this.searchPharmacy != null) {
        this.loading = true;
        this.$http
          .get("/Pharmacies?Search=" + this.searchPharmacy, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.pharmacies = res.data.data;
            this.loading = false;
          });
      // }
    },

    searchProduct() {
      if (this.searchProduct != "" && this.searchProduct != null) {
        this.products = [];
        this.loading = true;
        this.$http.get("/Products?Search=" + this.searchProduct).then((res) => {
          this.products = res.data.data;
          this.loading = false;
        });
      }
    },
    searchDoctor() {
      if (this.searchDoctor != "" && this.searchDoctor != null) {
        this.doctors = [];
        this.loading = true;
        this.$http
          .get("/Doctors?Search=" + this.searchDoctor, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.doctors = res.data.data;
            this.loading = false;
          });
      }
    },
    searchRepository() {
      if (this.searchRepository != "" && this.searchRepository != null) {
        this.repositories = [];
        this.loading = true;
        this.$http
          .get("/Repositories?Search=" + this.searchRepository, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
              CompanyId: this.$store.state.userInfo.userData.companyId,
            },
          })
          .then((res) => {
            this.repositories = res.data.data;
            this.loading = false;
          });
      }
    },
    $route(to) {
      this.type = to.params.type;
      this.prefix = to.params.type.toLowerCase();
      this.getReports();
    },
  },
};
</script>
