<template>
<div>
    <!-- use drawer -->
    <drawer title="Archive" :items="drawer.items" icon="archive"> </drawer>
    <orders v-if="tab == 'orders'" />
    <!-- use products -->
    <products v-if="tab == 'products'" />
    <!-- use Financial -->
    <financial v-if="tab == 'financial'" />
    <!-- use DoctorReports -->
    <DoctorReports v-if="tab == 'doctorReport'" />
    <!-- use PharmacyReports -->
    <PharmacyReports v-if="tab == 'pharmacyReport'" />
    <repository-reports v-if="tab == 'repositoryReport'" />
    <payment-overdue v-if="tab == 'overdue'" />
    <financial-dues v-if="tab == 'FinancialDues'" />
    <UnpaidOrders v-if="tab == 'unpaid'" />
    <ProductsSales v-if="tab == 'productsSales'" />
</div>
</template>

<script>
import Drawer from "@/layouts/Drawer";
import Orders from "../accounts/orders/Orders";
import Products from "../accounts/products/Products.vue";
import Financial from "../accounts/stipend/financial/Financial.vue";
import DoctorReports from "./DoctorReports/DoctorReports.vue";
import PharmacyReports from "./PharmacyReports/PharmacyReports.vue";
import RepositoryReports from "./RepositoryReports/RepositoryReports.vue";
import PaymentOverdue from "./PaymentOverdue/PaymentOverdue.vue";
import FinancialDues from "./FinancialDues/FinancialDues.vue";
import UnpaidOrders from "./UnpaidOrders/UnpaidOrders.vue";
import ProductsSales from "./ProductsSales/ProductsSales.vue";
export default {
    components: {
        Drawer,
        Orders,
        Products,
        Financial,
        DoctorReports,
        PharmacyReports,
        RepositoryReports,
        PaymentOverdue,
        FinancialDues,
        UnpaidOrders,
        ProductsSales,
    },
    data() {
        return {
            drawer: {
                items: [{
                        title: "تقارير الاطباء",
                        icon: "mdi-file-cabinet",
                        children: [{
                                title: "من التطبيق",
                                route: "archive-doctorReport",
                            },
                            {
                                title: "كشف حساب",
                                route: {
                                    name: "archive-financialDues",
                                    params: {
                                        type: "doctor"
                                    },
                                },
                            },
                            {
                                title: "مبيعات القطع",
                                route: {
                                    name: "archive-productsSales",
                                    params: {
                                        type: "doctor"
                                    },
                                },
                            },
                            {
                                title: "القوائم الغير مسددة",
                                icon: "mdi-file-cabinet",
                                route: "DoctorOrdersUnpaid",
                                access: true,
                            },
                            // {
                            //   title: "قوائم غير مسددة",
                            //   route: {
                            //     name: "archive-unpaid",
                            //     params: { type: "doctor" },
                            //   },
                            // },
                        ],
                        access: true,
                    },
                    {
                        title: "تقارير الصيدليات",
                        icon: "mdi-file-cabinet",
                        children: [{
                                title: "من التطبيق",
                                route: "archive-pharmacyReport",
                            },
                            {
                                title: "كشف حساب",
                                route: {
                                    name: "archive-financialDues",
                                    params: {
                                        type: "pharmacy"
                                    },
                                },
                            },
                            {
                                title: "مبيعات القطع",
                                route: {
                                    name: "archive-productsSales",
                                    params: {
                                        type: "pharmacy"
                                    },
                                },
                            },
                            {
                                title: "القوائم الغير مسددة",
                                icon: "mdi-file-cabinet",
                                route: "PharmcayOrdersUnpaid",
                                access: true,
                            },
                            // {
                            //   title: "قوائم غير مسددة",
                            //   route: {
                            //     name: "archive-unpaid",
                            //     params: { type: "pharmacy" },
                            //   },
                            // },
                        ],
                        access: true,
                    },
                    {
                        title: "تقارير المذاخر",
                        icon: "mdi-file-cabinet",
                        children: [{
                                title: "من التطبيق",
                                route: "archive-repositoryReport",
                            },
                            {
                                title: "كشف حساب",
                                route: {
                                    name: "archive-financialDues",
                                    params: {
                                        type: "repository"
                                    },
                                },
                            },
                            {
                                title: "مبيعات القطع",
                                route: {
                                    name: "archive-productsSales",
                                    params: {
                                        type: "repository"
                                    },
                                },
                            },
                            {
                                title: "القوائم الغير مسددة",
                                icon: "mdi-file-cabinet",
                                route: "RepositoryOrdersUnpaid",
                                access: true,
                            },
                            // {
                            //   title: "قوائم غير مسددة",
                            //   route: {
                            //     name: "archive-unpaid",
                            //     params: { type: "repository" },
                            //   },
                            // },
                        ],
                        access: true,
                    },

                    // {
                    //   title: "المتأخرين عن التسديد",
                    //   icon: "mdi-file-cabinet",
                    //   route: "archive-overdue",
                    //   access: true,
                    // },
                    // {
                    //   title: "المتأخرين عن التسديد",
                    //   icon: "mdi-file-cabinet",
                    //   route: "PharmacyOrdersUnpaid",
                    //   access: true,
                    // },
                    // {
                    //   title: "الصيدليات حسب المنطقة",
                    //   icon: "mdi-file-cabinet",
                    //   route: "archive-overdue",
                    //   access: true,
                    // },
                ],
            },
        };
    },

    computed: {
        tab() {
            return this.$route.path.split("/")[2];
        },
    },
    created() {
        if (this.tab == undefined) {
            for (let i = 0; i < this.drawer.items.length; i++) {
                if (
                    this.drawer.items[i].access == undefined ||
                    this.drawer.items[i].access == true
                ) {
                    if (this.drawer.items[i].children != undefined) {
                        // if (this.drawer.items[i].children[i].access) {
                        this.$router.push(
                            typeof this.drawer.items[i].children[0].route == "string" ? {
                                name: this.drawer.items[i].children[0].route,
                            } :
                            this.drawer.items[i].children[0].route
                        );
                        break;
                        // }
                    } else {
                        this.$router.push(
                            typeof this.drawer.items[i].route == "string" ? {
                                name: this.drawer.items[i].route,
                            } :
                            this.drawer.items[i].route
                        );
                        break;
                    }
                }
            }
        }
    },
    beforeMount() {
        this.$store.commit("TOGGLE_DRAWER", true);
    },
};
</script>
