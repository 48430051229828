<template>
  <div class="p-2" dir="rtl">
    <div
      class="d-flex justify-content-between justify-space-between border box grey lighten-1 pa-3 p-3 rounded my-1"
      style="width: 100%"
    >
      <div
        class="d-flex flex-column justify-space-between justify-content-between"
      >
        <div
          class="d-flex align-center justify-space-around justify-content-around"
        >
          <v-icon>mdi-phone</v-icon>
          <v-col class="text-right" dir="ltr">
            <!-- <h4>{{ $store.state.userData.office.numberPhone }}</h4> -->
            <!-- <h4>{{ getNumber }}</h4> -->
            <h4>0780 808 0586</h4>
            <h4>0782 210 3349</h4>
            <h4>0771 322 7788</h4>
          </v-col>
        </div>
      </div>
      <h1>{{ getOfficeName }}</h1>
      <v-img :src="getLogo" max-height="100" max-width="100" alt="" />
    </div>
    <div
      class="d-flex align-center align-items-center justify-space-between justify-content-between mt-10"
    >
      <div></div>
      <div>
        <h3 class="text-decoration-underline mb-1 text-center">كشف حساب</h3>
        <h4 class="text-center border box">
          <span v-if="!filtered">
            {{ products[0][prefix + "Order"][prefix].fullName }}
          </span>
          <span v-else> عام </span>
        </h4>
        <!-- <td>
          صيدلية التجربة
        </td> -->
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <td>للفترة من</td>
              <td>
                <span v-if="options.startDate">
                  {{ $service.formatDate(options.startDate, false) }}
                </span>
              </td>
            </tr>
            <tr>
              <td>الى</td>
              <td>
                <span v-if="options.endDate">
                  {{ $service.formatDate(options.endDate, false) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <table>
        <thead>
          <tr>
            <th>النوع</th>
            <th>المسدد</th>
            <th>الديون</th>
            <th>الكمية</th>
            <th>السعر</th>
            <th>المنتج</th>
            <th>الزبون</th>
            <th>التاريخ</th>
            <th>الرقم</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(product,index) in products" :key="`print` + product.id">
            <td>
              <span v-if="product.debt"> بيع </span>
              <span v-else> قبض </span>
            </td>
            <td>
              <span v-if="product.receipt">
                {{ $service.formatCurrency(product.receiptAmount) }}
              </span>
            </td>
            <td>
              <span v-if="!product.receipt">
                {{
                  $service.formatCurrency(
                    (product.product.realUintPrice * (product.product.qty + product.product.bonus ) ) - (product.product.discount * product.product.qty)
                  )
                }}
                <!-- $service.formatCurrency(( product.product.realUintPrice * product.product.qty ) - ( product.product.discount * product.product.qty )) -->
              </span>
            </td>
            
            <td>
              <span v-if="!product.receipt">
                {{ product.product.qty }}
              </span>
            </td>
            <td>
              <span v-if="!product.receipt">
                {{ $service.formatCurrency(product.product.price) }}
              </span>
            </td>
            <td>
              <span v-if="!product.receipt">
                {{ product.product.product.tradeName }}
              </span>
            </td>
            <td>
              <span>
                {{products[index][prefix + "Order"][prefix].fullName}}
              </span>
            </td>
            <td>
              {{ $service.formatDate(product.created, false) }}
            </td>
            <td>
              {{ product[`${prefix}Order`].orderNO }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="d-flex justify-start justify-content-start text-right mt-3"
        dir="rtl"
      >
        <div dir="rtl" class="box p-2 pa-2">
          <h4 dir="rtl">
            مجموع المسدد:
            <span class="font-weight-bold">
              {{ $service.formatCurrency(totalReceipts) }}
            </span>
          </h4>

          <v-divider></v-divider>
          <h4 class="my-2">
            مجموع الديون:
            <span class="font-weight-bold">
              {{ $service.formatCurrency(totalDebts) }}
            </span>
          </h4>
          <v-divider></v-divider>
          <h4 class="my-2">
            مجموع المتبقي:
            <span class="font-weight-bold">
              {{ $service.formatCurrency(totalDebts - totalReceipts) }}
            </span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const moment = require("moment");
export default {
  computed: {
    getLogo() {
      return (
        JSON.parse(localStorage.getItem("userInfo")).office.logoUrl || false
      );
    },
    getNumber() {
      return (
        JSON.parse(localStorage.getItem("userInfo")).userData.phoneNumber ||
        false
      );
    },
    getOfficeName() {
      return JSON.parse(localStorage.getItem("userInfo")).office.name || false;
    },
    totalDebts() {
      return this.products
        .filter((v) => v.receipt == false)
        .reduce((acc, product) => {
          return (
            acc +
            (product.product.realUintPrice * (product.product.qty + product.product.bonus ) ) - (product.product.discount * product.product.qty)
              
          );
        }, 0);
    },
    totalReceipts() {
      // return this.products
      //   .filter((v) => v.receipt == true)
      //   .reduce((acc, product) => {
      //     return (
      //       acc +
      //       (product.product.price - product.product.discount) *
      //         product.product.qty
      //     );
      //   }, 0);
      let total = 0;
      for (let i = 0; i < this.products.length; i++) {
        if(this.products[i].receipt == true){
          total += this.products[i].receiptAmount
        }
      }
      return total;
    },
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: "",
    },
    office: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    count: {
      type: Number,
    },
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
thead {
  background-color: #ddd;
}
td,
th {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}
.divider {
  border-top: 1px solid black;
  margin: 10px 0;
}
.box {
  border: 1px solid #000000;
  border-radius: 1px !important;
  direction: rtl;
}
@media print {
  * {
    /* background-color: #1a4567 !important; */
    -webkit-print-color-adjust: exact;
  }
  .box {
    border: 1px solid #000000;
    direction: rtl;
    border-radius: 1px !important;
  }
}
</style>
