<template>
  <div>
    <product-batch
      @updated="
        (_) => {
          productPath = _;
          getProducts();
        }
      "
      @added="
        (_) => {
          dialog = _;
          getProducts();
        }
      "
      :dialog="productPath"
    />
    <h1 class="mb-3 d-flex align-content-center">
      {{ $t("products") }}
      <v-spacer></v-spacer>
      <v-btn class="mr-10" large color="secondary" @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
        {{ $t("add") }} {{ $t("product") }}</v-btn
      >
    </h1>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col v-if="$service.hideFrom((v) => [v > 0])" cols="4">
          <label for="comp">{{ $t("company") }}</label>
          <v-autocomplete
            class="mt-2"
            v-model="options.CompanyId"
            :items="companies"
            item-text="name"
            :label="$t('company')"
            item-value="id"
            clearable
            :loading="loading"
            :no-data-text="$t('no-data')"
            solo
            @change="getProducts()"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="4">
          <label for="comp">{{ $t("warehouse") }}</label>
          <v-select
            class="mt-2"
            @change="getProducts()"
            clearable
            v-model="options.WarehouseId"
            :items="warehouses"
            :search-input.sync="searchWarehouse"
            :loading="loading"
            :no-data-text="
              searchWarehouse == '' && searchWarehouse == null
                ? 'اكتب للبحث ...'
                : 'لا توجد بيانات'
            "
            solo
            item-value="id"
            item-text="name"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="4">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <product
        @updated="
          (_) => {
            dialog = _;
            getProducts();
          }
        "
        :dialog="dialog"
      />
      <v-data-table
        disable-pagination
        :headers="headers"
        :items="products"
        :loading="loading"
        hide-default-footer
        :no-data-text="$t('no-data')"
        @click:row="
          (_) => {
            $router.push({ name: 'product', params: { id: _.id } });
          }
        "
      >
        <template v-slot:item.created="{ item }">
          <span dir="ltr">
            {{ formatDate(new Date(item.created)) }}
          </span>
        </template>
        <template #item.edit="{ item }">
          <div class="d-flex">
            <!-- <v-btn @click="openProductBatch(item)" large color="secondary">
              <v-icon class="mx-2" small>mdi-truck-delivery</v-icon>
              make batch
            </v-btn> -->

            <v-btn
              @click.stop="editProduct(item)"
              large
              color="primary"
              class="mx-1"
            >
              <v-icon class="mx-2" small>mdi-pencil</v-icon>
              {{ $t("edit") }}
            </v-btn>
            <!-- <v-btn
              :to="{
                name: 'product',
                params: {
                  id: item.id,
                },
              }"
              large
              color="warning"
              class="mx-1"
              text
            >
              <v-icon class="mx-2" small>mdi-eye</v-icon>
              Detials
            </v-btn> -->
          </div>
        </template>
      </v-data-table>
      <div class="d-flex justify-center">
        <v-pagination
          v-model="options.PageIndex"
          :length="parseInt(total / options.PageSize) + 1"
        />
      </div>
    </v-card>
  </div>
</template>
<script>
import Product from "./Product";
import ProductBatch from "./ProductBatch";
import { formatDate } from "@/utils";
export default {
  components: {
    Product,
    ProductBatch,
  },
  data() {
    return {
      company: {},
      options: {
        CompanyId: "",
        WarehouseId: "",
        Search: "",
        PageSize: 10,
        PageIndex: 1,
        OfficeId: this.$store.state.userInfo.office.id,
      },
      warehouses: [],
      loading: true,
      dialog: false,
      productPath: false,
      companies: [],
      total: 0,
      headers: [
        {
          text: this.$t("scientific-name"),
          align: "start",
          sortable: false,
          value: "scientificName",
        },
        {
          text: this.$t("trade-name"),
          align: "start",
          sortable: false,
          value: "tradeName",
        },
        {
          text: this.$t("price"),
          value: "price",
        },
        {
          text: this.$t("dose"),
          value: "dose",
        },
        {
          text: this.$t("dosage-form-0"),
          value: "productDosageFormName",
        },
        {
          text: this.$t("batchs"),
          value: "batchCount",
        },

        { text: this.$t("warehouse"), value: "warehouseName" },
        { text: "", value: "edit", sortable: false },
      ],
      products: [],
      searchWarehouse: "",
    };
  },
  mounted() {
    // if (this.$route.params.warehouseId !== undefined) {
    //   this.options.WarehouseId = this.$route.params.warehouseId;
    // }
  },
  async created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        this.options.CompanyId = this.$store.state.company.id;
        this.companies.unshift({ id: "", name: "All" });
        if (this.role >= 1) {
          this.options.CompanyId = this.$store.state.userInfo.userData.companyId;
        }
        this.getWarehouses();
        this.getProducts();
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
  watch: {
    "options.Search"() {
      this.getProducts();
    },
    options: {
      handler() {
        this.getProducts();
      },
      deep: true,
    },
  },
  methods: {
    formatDate,
    getWarehouses() {
      let search = "";
      if (this.searchWarehouse == "" && this.searchWarehouse == null) {
        search = `Search=${this.searchWarehouse}`;
      }
      this.$http
        .get("/Warehouses" + search, {
          params: {
            CompanyId: this.$store.state.userInfo.userData.companyId,
            OfficeId: this.$store.state.userInfo.office.id,
          },
        })
        .then((war) => {
          this.warehouses = war.data.data;
          this.warehouses.unshift({ id: "", name: "All" });
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    editProduct(id) {
      this.$store.commit("products/updateProduct", id);
      this.$store.commit("products/toggleEdit", true);
      this.dialog = true;
    },
    openProductBatch(id) {
      this.$store.commit("products/updateProduct", id);
      this.productPath = true;
    },
    getProducts() {
      this.loading = true;
      let params = {};
      // this.options.CompanyId = this.$store.state.company.id;
      Object.keys(this.options).forEach((e) => {
        if (this.options[e] !== null && this.options[e] !== "") {
          params[e] = this.options[e];
        }
      });
      this.$http
        .get("/Products", { params: params })
        .then((prod) => {
          this.products = prod.data.data;
          this.loading = false;
          this.total = prod.data.count;
          // if (
          //   this.$store.state.warehouses.warehouse !== null &&
          //   Object.keys(this.$store.state.warehouses.warehouse).length > 0
          // ) {
          //   this.options.WarehouseId =
          //     this.$store.state.warehouses.warehouse.id;
          // }
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
};
</script>
