var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.fakeDialog),callback:function ($$v) {_vm.fakeDialog=$$v},expression:"fakeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Check if report is fake ")]),(_vm.fakeMessage != null)?_c('v-card-text',_vm._l((_vm.fakeMessage.split(';')),function(message,i){return _c('h4',{key:i,staticClass:"my-1"},[_vm._v(" "+_vm._s(i + 1)+"- "+_vm._s(message)+" ")])}),0):_c('v-card-text',[_c('h4',[_vm._v("Report isn't fake")])])],1)],1),(_vm.doctor == null && !_vm.child)?_c('div',[_c('h1',[_vm._v("Doctor Reports")]),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","filled":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mt-2",attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.reports,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data')},scopedSlots:_vm._u([{key:"item.fake",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showWarings(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" Show warnings ")],1)]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [(item.products.length > 0)?[_vm._v(" "+_vm._s(item.products[0].product.tradeName)+" ")]:_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")])]}},{key:"item.visitWith",fn:function(ref){
var item = ref.item;
return [(item.products.length > 0)?[_vm._l((item.products[0].visitWiths),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":"","small":""}},[_vm._v(_vm._s(visit.visitWith.name))]):_vm._e()]})]:_vm._e()]}},{key:"item.visitType",fn:function(ref){
var item = ref.item;
return [(item.products.length > 0)?[_vm._l((item.products),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":""}},[_vm._v(_vm._s(visit.visitType.name))]):_vm._e()]})]:_vm._e()]}},{key:"item.visitSummary",fn:function(ref){
var item = ref.item;
return [(item.products.length > 0)?[_vm._l((item.products),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":""}},[_vm._v(_vm._s(visit.visitSummary.name))]):_vm._e()]})]:_vm._e()]}}],null,false,1126474611)}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1):_c('div',[_c('v-data-table',{staticClass:"mt-2",attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.reports,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data')},scopedSlots:_vm._u([{key:"item.fake",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showWarings(item)}}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" Show warnings ")],1)]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.products[0].product.tradeName)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.$service.formatDate(new Date(item.created)))+" ")])]}},{key:"item.visitWith",fn:function(ref){
var item = ref.item;
return [_vm._l((item.products[0].visitWiths),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":""}},[_vm._v(_vm._s(visit.visitWith.name))]):_vm._e()]})]}},{key:"item.visitSummary",fn:function(ref){
var item = ref.item;
return [_vm._l((item.products),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":""}},[_vm._v(_vm._s(visit.visitSummary.name))]):_vm._e()]})]}},{key:"item.visitType",fn:function(ref){
var item = ref.item;
return [_vm._l((item.products),function(visit,i){return [(visit != null)?_c('v-chip',{key:item.id + i,staticClass:"mx-1",attrs:{"color":"primary","label":"","active":""}},[_vm._v(_vm._s(visit.visitType.name))]):_vm._e()]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }