<template>
  <div class="p-2">
    <div
      class="
        d-flex
        justify-content-between justify-space-between
        border
        box
        grey
        lighten-1
        pa-3
        p-3
        rounded
        my-1
      "
    >
      <div
        class="d-flex flex-column justify-space-between justify-content-between"
      >
        <div
          class="
            d-flex
            align-center
            justify-space-around justify-content-around
          "
        >
          <v-icon>mdi-phone</v-icon>
          <v-col class="text-right" dir="ltr">
            <!-- <h4>{{ $store.state.userData.office.numberPhone }}</h4> -->
            <h4>0780 808 0586</h4>
            <h4>0782 210 3349</h4>
            <h4>0771 322 7788</h4>
          </v-col>
        </div>
      </div>
      <h1>{{ this.$store.state.userData.office.name }}</h1>
      <v-img
        :src="this.$service.url + this.$store.state.userData.office.logoUrl"
        @load="loaded()"
        max-height="100"
        max-width="100"
        alt=""
      />
    </div>
    <div
      class="
        d-flex
        align-center align-items-center
        justify-space-between justify-content-between
        mt-5
       
      "
    >
      <div>{{$service.formatDate(new Date())}}</div>
      <div>
        <h3 class="text-decoration-underline mb-1 text-center">مبيعات القطع</h3>
        <h4 class="text-center border box mt-2">
          <span v-if="!filtered">
            {{ products[0][prefix].fullName }}
          </span>
          <span v-else> عام </span>
        </h4>
        <!-- <td>
          صيدلية التجربة
        </td> -->
      </div>
      <div>
        <h4>
            
        </h4>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>{{prefix}}</th>
            <th>Product</th>
            <th>Sold No.</th>
            <th>Bonus</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product,i) in products" :key="`print` + product.id">
            <td>
              {{ i + 1 }}
            </td>
            <td>
                {{ product[prefix].fullName }}
            </td>
            <td>
                {{ product.product.tradeName }}
            </td>
            <td>
                {{ product.qty }}
            </td>
            <td>
                {{
                    product.bonus
                    
                }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
const moment = require("moment");
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: "",
    },
    office: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
thead {
  background-color: #ddd;
}
td,
th {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}
.divider {
  border-top: 1px solid black;
  margin: 10px 0;
}
.box {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 1px !important;
}
@media print {
  * {
    /* background-color: #1a4567 !important; */
    -webkit-print-color-adjust: exact;
  }
  .box {
    width: 100%;
    border: 1px solid #000000;
    border-radius: 1px !important;
  }
}
</style>
