<template>
  <div class="p-2" dir="rtl">
    <div
      class="d-flex justify-content-between justify-space-between border box grey lighten-1 pa-3 p-3 rounded my-1"
    >
      <div
        class="d-flex flex-column justify-space-between justify-content-between"
      >
        <div
          class="d-flex align-center justify-space-around justify-content-around"
        >
          <v-icon>mdi-phone</v-icon>
          <v-col class="text-right" dir="ltr">
            <!-- <h4>{{ $store.state.userData.office.numberPhone }}</h4> -->
            <h4>0780 808 0586</h4>
            <h4>0782 210 3349</h4>
            <h4>0771 322 7788</h4>
          </v-col>
        </div>
      </div>
      <h1>{{ this.$store.state.userData.office.name }}</h1>
      <v-img
        src="https://api.dev.scodefield.com/Content/uploads/2fbec1e6-f25e-4c7f-abfe-f3763a5d7b57_photo_2022-06-13_15-04-08.jpg"
        max-height="100"
        max-width="100"
        alt=""
      />
    </div>
    <div
      class="d-flex align-center align-items-center justify-space-between justify-content-between mt-10"
    >
      <div></div>
      <div>
        <h3 class="text-decoration-underline mb-1 text-center">
          القوائم الغير مسددة
        </h3>
        <h4 class="text-center border box">
          <span> عام </span>
        </h4>
        <!-- <td>
          صيدلية التجربة
        </td> -->
      </div>
      <div></div>
    </div>
    <div class="divider"></div>
    <div>
      <table>
        <thead>
          <tr>
            <!-- <th>الرصيد</th> -->
            <th>رقم القائمة</th>
            <th>التاريخ</th>
            <th>المندوب</th>
            <th>الكمية</th>
            <th>السعر</th>
            <th>الواصل</th>
            <th>المتبقي</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in products" :key="`print` + report.id">
            <td>
              {{ report[prefix + "Order"].orderNO }}
            </td>
            <td>
              {{ $service.formatDate(report.created, false) }}
            </td>
            <td>
              {{ report[prefix + "Order"].userCreatedFullName }}
            </td>
            <td>
              {{ report[prefix + "Order"][prefix].fullName }}
            </td>
            <td>
              {{ $service.formatCurrency(report[prefix + "Order"].totalPrice) }}
            </td>
            <td>
              {{
                $service.formatCurrency(report[prefix + "Order"].amountReceived)
              }}
            </td>
            <td>
              {{
                $service.formatCurrency(
                  report[prefix + "Order"].totalPrice -
                    report[prefix + "Order"].amountReceived
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
const moment = require("moment");
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: "",
    },
    office: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    count: {
      type: Number,
    },
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
thead {
  background-color: #ddd;
}
td,
th {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}
.divider {
  border-top: 1px solid black;
  margin: 10px 0;
}
.box {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 1px !important;
}
@media print {
  * {
    /* background-color: #1a4567 !important; */
    -webkit-print-color-adjust: exact;
  }
  .box {
    width: 100%;
    border: 1px solid #000000;
    border-radius: 1px !important;
  }
}
</style>
