<template>
  <div class="p-2" dir="rtl">
    <div
      class="d-flex justify-content-between justify-space-between border box grey lighten-1 pa-3 p-3 rounded my-1"
    >
      <div
        class="d-flex flex-column justify-space-between justify-content-between"
      >
        <h1>{{ this.$store.state.userData.office.name }}</h1>
        <div
          class="d-flex align-center justify-space-around justify-content-around"
        >
          <v-icon>mdi-phone</v-icon> {{
            this.$store.state.userData.office.numberPhone
          }}
        </div>
      </div>
      <v-img
        :src="this.$service.url + this.$store.state.userData.office.logoUrl"
        @load="loaded()"
        max-height="100"
        max-width="100"
        alt=""
      />
    </div>
    <div
      class="d-flex align-center align-items-center justify-space-between justify-content-between "
    >
      <div>
        <h3 class="text-decoration-underline text-center mu-1">
          كشف المتاخرين عن التسديد
        </h3>
        <!-- <td>
          صيدلية التجربة
        </td> -->
      </div>
      <div>
        <!-- <table>
          <tbody>
            <tr>
              <td>للفترة من</td>
              <td></td>
            </tr>
            <tr>
              <td>الى</td>
              <td></td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <table>
        <thead>
          <tr>
            <th>
              الرقم
            </th>
            <th>
              الاسم
            </th>
            <th>
              الرصيد
            </th>
            <th>اخر حركة</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in reports" :key="`print` + index">
            <td>
              {{ index + 1 }}
            </td>
            <td>
              <span v-if="item.pharmacy"> ص.{{ item.pharmacy.fullName }} </span>
              <span v-if="item.doctor"> د.{{ item.doctor.fullName }} </span>
              <span v-if="item.repository">
                م.{{ item.repository.fullName }}
              </span>
            </td>
            <td>
              <span v-if="item.pharmacy">
                {{ item.pharmacy.debts }}
              </span>
              <span v-if="item.doctor">
                {{ item.doctor.debts }}
              </span>
              <span v-if="item.repository">
                {{ item.repository.debts }}
              </span>
            </td>
            <td>
              {{ $service.formatDate(item.lasMoveDate, false) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: "",
    },
    office: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 1px !important;
}
table {
  width: 100%;
}
thead {
  background-color: #ddd;
}
td,
th {
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}
.divider {
  border-top: 1px solid black;
  margin: 10px 0;
}
@media print {
  * {
    /* background-color: #1a4567 !important; */
    -webkit-print-color-adjust: exact;
  }
  .box {
    width: 100%;
    border: 1px solid #000000;
    border-radius: 1px !important;
  }
}
</style>
