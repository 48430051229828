<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="text-h5 mt-4">
        {{ isEdit ? $t("edit-product") : $t("new-product") }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form ref="addProduct" :loading="loading">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.scientificName"
                    :label="$t('scientific-name')"
                    outlined
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.tradeName"
                    :label="$t('trade-name')"
                    outlined
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.bouns"
                    :label="$t('bouns')"
                    outlined
                    placeholder="5 + 10"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.price"
                    :label="$t('price')"
                    @input="
                      inputs.price = $service.formatCurrency(inputs.price)
                    "
                    placeholder="0.0"
                    :pattern="/^-?\d+\.?\d*$/"
                    suffix="IQD"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.discount"
                    :label="$t('discount')"
                    @input="
                      inputs.discount = $service.formatCurrency(inputs.discount)
                    "
                    placeholder="0.0"
                    :pattern="/^-?\d+\.?\d*$/"
                    suffix="IQD"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :items="dosageForms"
                    v-model="inputs.productDosageFormId"
                    :label="$t('dosage-form')"
                    hide-detials="auto"
                    outlined
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.dose"
                    :label="$t('dose')"
                    placeholder="0 x 0"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="inputs.warehouseId"
                    :label="$t('warehouse')"
                    hide-detials="auto"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    outlined
                    item-value="id"
                    :items="warehouses"
                    item-text="name"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="file"
                    :label="$t('image')"
                    outlined
                    hide-details
                    accept="image/*"
                  ></v-file-input>
                  <v-progress-linear
                    v-if="progress"
                    :value="uploadProgress"
                  ></v-progress-linear>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    :label="$t('description')"
                    hide-detials="auto"
                    v-model="inputs.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t("cancel") }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addProduct">
          <v-icon>mdi-plus</v-icon>
          {{ $t("save") }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.products.isEdit;
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
      uploadProgress: 0,
      file: null,
      progress: false,
      warehouses: [],
      imageFile: null,
      dosageForms: [],
      inputs: {
        tradeName: "",
        scientificName: "",
        description: "",
        warehouseId: "",
        pictureUrl: "",
        bouns: "",
        dose: "",
        productDosageFormId: "",
        discount: 0,
        price: 0,
        deal: 0,
      },
      mounted: false,
    };
  },
  created() {
    this.loading = true;
    this.$http
      .get("/Warehouses", {
        params: {
          CompanyId: this.$store.state.company.id,
        },
      })
      .then((war) => {
        this.warehouses = war.data.data;

        if (
          this.$store.state.warehouses.warehouse !== null &&
          Object.keys(this.$store.state.warehouses.warehouse).length > 0
        ) {
          this.inputs.warehouseId = this.$store.state.warehouses.warehouse.id;
        }
        this.$http.get("/Products/productDosageForm").then((dosageForms) => {
          this.dosageForms = dosageForms.data.result;
          this.loading = false;
        });
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
  mounted() {
    this.mounted = true;
  },
  props: ["dialog"],
  methods: {
    addProduct() {
      var val = this.$refs.addProduct.validate();
      if (val) {
        this.loading = true;

        this.inputs.price = this.inputs.price.toString().replace(/,/g, "");
        this.$store.dispatch("uploadFile", this.file).then((url) => {
          if (typeof url === "string") {
            this.inputs.pictureUrl = url;
          }
          if (typeof this.inputs.pictureUrl === "object") {
            this.inputs.pictureUrl = "";
          }
          if (!this.isEdit) {
            this.$http
              .post("/Products/post", this.inputs)
              .then((res) => {
                if (res.data.statusCode == 201) {
                  this.$emit("added", !this.dialog);
                  this.dialog = false;
                }
              })
              .catch((e) => {
                this.$store.commit("UPDATE_SNACKBAR", true);
                this.$store.commit(
                  "UPDATE_SNACKBAR_MESSAGE",
                  e.response.data.message
                );
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$http
              .put(
                "/Products/put/" + this.$store.state.products.product.id,
                this.inputs
              )
              .then(() => {
                this.dialog = false;
              })
              .catch((e) => {
                this.$store.commit("UPDATE_SNACKBAR", true);
                this.$store.commit(
                  "UPDATE_SNACKBAR_MESSAGE",
                  e.response.data.message
                );
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    },
    checkIsEdit() {
      if (this.isEdit) {
        var user = this.$store.state.products.product;

        Object.keys(user).forEach((key) => {
          if (this.inputs[key] !== undefined) {
            this.inputs[key] = user[key];
          }
          if (key == "warehouseName") {
            var warehouse = this.warehouses.find((v) => v.name == user[key]);
            this.inputs.warehouseId = warehouse.id;
          }
          if(key == 'productDosageFormName'){
            var dosageForm = this.dosageForms.find((v) => v.name == user[key]);
            this.inputs.productDosageFormId = dosageForm.id;
          }
        });
      } else {
        this.$store.commit("products/updateProduct", {});
        this.$store.commit("products/toggleEdit", false);
        this.inputs = {
          tradeName: "",
          scientificName: "",
          description: "",
          warehouseId: "",
          pictureUrl: "",
          bouns: "",
          dose: "",
          productDosageFormId: "",
          discount: 0,
          price: 0,
          deal: 0,
        };
      }
    },
  },
  watch: {
    dialog() {
      if (this.$refs.addProduct !== undefined) {
        this.$refs.addProduct.reset();
        if (
          this.$store.state.warehouses.warehouse !== null &&
          Object.keys(this.$store.state.warehouses.warehouse).length > 0
        ) {
          this.inputs.warehouseId = this.$store.state.warehouses.warehouse.id;
        }
      }
      if (this.dialog != true) {
        this.$store.commit("products/updateProduct", {});
        this.$store.commit("products/toggleEdit", false);
        this.$emit("updated", this.dialog);
      } else {
        this.checkIsEdit();
      }
      // if (
      //     this.$store.state.warehouses.warehouse !== null &&
      //     Object.keys(this.$store.state.warehouses.warehouse).length > 0
      //   ) {
      this.inputs.warehouseId = this.$store.state.warehouses.warehouse.id;
      // }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
