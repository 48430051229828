<template>
  <div>
    <div v-if="pharmacy == null && !child">
      <h1>Pharmacy Reports</h1>
      <v-row>
        <v-col cols="3" style="padding-bottom: 0">
           <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
disable-pagination
            :headers="headers"
            :items="reports"
            :loading="loading"
            hide-default-footer
            :no-data-text="$t('no-data')"
            class="mt-2"
          >
            <template v-slot:item.products="{ item }">
              {{ item.product.tradeName }}
            </template>
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created)) }}
              </span>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-data-table
disable-pagination
        :headers="headers"
        :items="reports"
        :loading="loading"
        hide-default-footer
        :no-data-text="$t('no-data')"
        class="mt-2"
      >
        <template v-slot:item.products="{ item }">
          {{ item.product.tradeName }}
        </template>
        <template v-slot:item.created="{ item }">
          <span dir="ltr">
            {{ $service.formatDate(new Date(item.created)) }}
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    pharmacy: {
      type: Object,
      default: null
    },
    child:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    options: {
      handler() {
        this.getReports();
      },
      deep: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('created-by'),
          value: "userCreatedFullName",
        },
        {
          text: "Date",
          value: "created",
        },
        {
          text: "Pharmacy",
          value: "pharmacyFullName",
        },
        {
          text: this.$t('region'),
          align: "start",
          value: "regionName",
        },
        {
          text: "Products",
          value: "products",
        },
        {
          text: this.$t('note'),
          value: "note",
        },
      ],
      reports: [],
      loading: false,
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
        CompanyId: this.$store.state.company.id,
      },
      total: 0,
    };
  },
  methods: {
    getReports() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get("/PharmacyReports" + filter.replace("?&", "?"))
        .then((prod) => {
          this.total = prod.data.count;
          this.reports = prod.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  created() {
    this.getReports();
  },
};
</script>