<template>
  <div>
    <v-card-title>
      <h1 class="mb-3">{{ $t("doctor-wallet") }}</h1>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-large @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
        {{ $t("add") }}
      </v-btn>
    </v-card-title>
    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="4">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label for="se">{{ $t("select-doctor") }}</label>
          <v-autocomplete
            hide-detials="auto"
            clearable
            v-model="options.DoctorId"
            :items="doctors"
            :search-input.sync="searchDoctor"
            :loading="loading"
            :no-data-text="$t('no-data')"
            class="mt-2"
            solo
            item-value="id"
            :label="$t('Doctor')"
            item-text="fullName"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="4">
          <label for="se">{{ $t("order") }}</label>
          <v-autocomplete
            hide-detials="auto"
            clearable
            v-model="options.PharmacyOrderId"
            :items="orders"
            :search-input.sync="searchOrder"
            :loading="loading"
            :no-data-text="$t('no-data')"
            class="mt-2"
            solo
            item-value="id"
            item-text="orderNO"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12">
          <v-chip-group
            v-model="options.IsPaid"
            :loading="loading"
            active-class="success"
          >
            <v-chip label x-large filter :value="0"> {{ $t("all") }} </v-chip>
            <v-chip label x-large filter :value="'true'">
              {{ $t("paid") }} </v-chip
            ><v-chip label x-large filter :value="'false'">
              {{ $t("not-paid") }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="finacialDues"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.created="{ item }">
                  {{ $service.formatDate(new Date(item.created)) }}
                </template>
                <template v-slot:item.amount="{ item }">
                  {{ $service.formatCurrency(item.amount) }}
                </template>
                <template v-slot:item.qty="{ item }">
                  {{ $service.formatCurrency(item.qty) }}
                </template>
                <template v-slot:item.totalAmount="{ item }">
                  {{ item.totalAmount }}
                </template>
                <template v-slot:item.isPaid="{ item }">
                  {{ item.isPaid ? $t("paid") : $t("not-paid") }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                    @click="openConfirm(item)"
                    color="success"
                    v-if="!item.isPaid"
                  >
                    <v-icon>mdi-check</v-icon>
                    {{ $t("pay") }}
                  </v-btn>
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog persistent v-model="confirmDialog" width="600">
      <v-card :loading="loading" outlined>
        <v-card-title class="my-5">
          {{ $t("confirm-payment") }}
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialog = !confirmDialog" text color="error">
            <f-icon icon="times-circle" />
          </v-btn>
        </v-card-title>
        <v-card-text >
          {{ $t("are-you-sure-you-want-to-pay") }}
        </v-card-text>
        <v-card-actions class="my-5">
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialog = !confirmDialog" outlined color="error">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn @click="pay(selectedItem)" color="success">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Dialog
      :dialog="dialog"
      @updated="
        (_) => {
          dialog = _;
          getFinacial();
        }
      "
    />
  </div>
</template>
<script>
import Dialog from "./Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      dialog: false,
      loading: false,
      options: {
        Search: "",
        CompanyId: this.$store.state.company.id,
        OfficeId: this.$store.state.userInfo.office.id,
        PageIndex: 1,
        PageSize: 50,
        IsPaid: null,
        DoctorId: null,
      },

      searchDoctor: "",
      doctors: [],
      confirmDialog: false,
      searchOrder: "",
      orders: [],
      total: 0,
      finacialDues: [],
      selectedItem: {},
      headers: [
        {
          text: this.$t("amount"),
          value: "amount",
        },
        {
          text: this.$t("sold-no"),
          value: "qty",
        },
        {
          text: this.$t("total-amounts"),
          value: "totalAmount",
        },
        {
          text: this.$t("paid"),
          value: "isPaid",
        },
        {
          text: this.$t("Doctor"),
          value: "doctor.fullName",
        },
        {
          text: this.$t("doctor-spec"),
          value: "doctor.specName",
        },
        {
          text: this.$t("doctor-class"),
          value: "doctor.className",
        },
        {
          text: this.$t("order"),
          value: "pharmacyOrder.orderNO",
        },
        {
          text: this.$t("note"),
          value: "note",
        },
        {
          text: this.$t('date-paid'),
          value: "datePaid",
        },
        {
          text: this.$t("created"),
          value: "created",
        },
        {
          text: this.$t("actions"),
          value: "action",
        },
      ],
    };
  },
  methods: {
    openConfirm(item) {
      this.selectedItem = item;
      this.confirmDialog = true;
    },
    getFinacial() {
      this.loading = true;
      if (this.options.IsPaid == "0" || this.options.IsPaid == 0)
        this.options.IsPaid = null;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/DoctorWallets" + filter.replace("?&", "?"))
        .then((finacialDues) => {
          this.total = finacialDues.data.count;
          this.finacialDues = finacialDues.data.data;
          this.finacialDues.map((item) => {
            item.totalAmount = item.amount * item.qty;
          });
          for (let i = 0; i < this.finacialDues.length; i++) {
            Object.keys(this.finacialDues[i]).forEach((key) => {
              if (typeof this.finacialDues[i][key] == "number") {
                this.finacialDues[i][key] =
                  "IQD " +
                  this.$service.formatCurrency(this.finacialDues[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    pay(item) {
      this.$http
        .delete("/DoctorWallets/paid/" + item.id)
        .then(() => {
          this.getFinacial();
          this.confirmDialog = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getFinacial();
      },
      deep: true,
    },
    searchDoctor() {
      if (this.searchDoctor !== "" && this.searchDoctor !== null) {
        this.doctors = [];
        this.loading = true;
        this.$http.get("/Doctors?Search=" + this.searchDoctor).then((res) => {
          this.doctors = res.data.data;
          this.loading = false;
        });
      }
    },
    searchOrder() {
      if (this.searchOrder !== "" && this.searchOrder !== null) {
        this.orders = [];
        this.loading = true;
        this.$http
          .get("/PharmacyOrders?Search=" + this.searchOrder)
          .then((res) => {
            this.orders = res.data.data;
            this.loading = false;
          });
      }
    },
  },
  created() {
    this.getFinacial();
    this.$http.get("/Doctors").then((res) => {
      this.doctors = res.data.data;
      this.$http.get("/PharmacyOrders").then((res) => {
        this.orders = res.data.data;
      });
    });
  },
};
</script>
