<template>
  <div>
    <v-dialog v-model="fakeDialog" width="700">
      <v-card>
        <v-card-title> Check if report is fake </v-card-title>
        <v-card-text v-if="fakeMessage != null">
          <h4
            class="my-1"
            v-for="(message, i) in fakeMessage.split(';')"
            :key="i"
          >
            {{ i + 1 }}- {{ message }}
          </h4>
        </v-card-text>
        <v-card-text v-else>
          <h4>Report isn't fake</h4>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="doctor == null && !child">
      <h1>Doctor Reports</h1>
      <v-row>
        <v-col cols="3" style="padding-bottom: 0">
          <label for="se">{{ $t("search") }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers"
            :items="reports"
            :loading="loading"
            hide-default-footer
            :no-data-text="$t('no-data')"
            class="mt-2"
          >
            <template v-slot:item.fake="{ item }">
              <v-btn color="primary" class="mx-1" @click="showWarings(item)">
                <v-icon>mdi-eye</v-icon>
                Show warnings
              </v-btn>
            </template>
            <template v-slot:item.products="{ item }">
              <template v-if="item.products.length > 0">
                {{ item.products[0].product.tradeName }}
              </template>
            </template>
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created)) }}
              </span>
            </template>
            <template v-slot:item.visitWith="{ item }">
              <!-- {{item.products[0].visitWiths}} -->
              <template v-if="item.products.length > 0">
                <template v-for="(visit, i) in item.products[0].visitWiths">
                  <v-chip
                    color="primary"
                    label
                    class="mx-1"
                    active
                    small
                    v-if="visit != null"
                    :key="item.id + i"
                    >{{ visit.visitWith.name }}</v-chip
                  >
                </template>
              </template>
            </template>
            <template v-slot:item.visitType="{ item }">
              <template v-if="item.products.length > 0">
                <template v-for="(visit, i) in item.products">
                  <v-chip
                    color="primary"
                    label
                    class="mx-1"
                    active
                    v-if="visit != null"
                    :key="item.id + i"
                    >{{ visit.visitType.name }}</v-chip
                  >
                </template>
              </template>
            </template>
            <template v-slot:item.visitSummary="{ item }">
              
             <template v-if="item.products.length > 0">
              <template v-for="(visit, i) in item.products">
                <v-chip
                  color="primary"
                  label
                  class="mx-1"
                  active
                  v-if="visit != null"
                  :key="item.id + i"
                  >{{ visit.visitSummary.name }}</v-chip
                >
              </template>
            </template>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-data-table
        disable-pagination
        :headers="headers"
        :items="reports"
        :loading="loading"
        hide-default-footer
        :no-data-text="$t('no-data')"
        class="mt-2"
      >
        <template v-slot:item.fake="{ item }">
          <v-btn color="primary" class="mx-1" @click="showWarings(item)">
            <v-icon>mdi-eye</v-icon>
            Show warnings
          </v-btn>
        </template>
        <template v-slot:item.products="{ item }">
          {{ item.products[0].product.tradeName }}
        </template>
        <template v-slot:item.created="{ item }">
          <span dir="ltr">
            {{ $service.formatDate(new Date(item.created)) }}
          </span>
        </template>
        <template v-slot:item.visitWith="{ item }">
          <!-- {{item.products[0].visitWiths}} -->
          <template v-for="(visit, i) in item.products[0].visitWiths">
            <v-chip
              color="primary"
              label
              class="mx-1"
              active
              v-if="visit != null"
              :key="item.id + i"
              >{{ visit.visitWith.name }}</v-chip
            >
          </template>
        </template>
        <template v-slot:item.visitSummary="{ item }">
          <template v-for="(visit, i) in item.products">
            <v-chip
              color="primary"
              label
              class="mx-1"
              active
              v-if="visit != null"
              :key="item.id + i"
              >{{ visit.visitSummary.name }}</v-chip
            >
          </template>
        </template>
        <template v-slot:item.visitType="{ item }">
          <!-- {{item.products[0].visitWiths}} -->
          <v-chip color="info" label class="mx-1" active>{{
            item.products[0].visitType.name
          }}</v-chip> </template
        ><template v-slot:item.visitType="{ item }">
          <template v-for="(visit, i) in item.products">
            <v-chip
              color="primary"
              label
              class="mx-1"
              active
              v-if="visit != null"
              :key="item.id + i"
              >{{ visit.visitType.name }}</v-chip
            >
          </template>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    doctor: {
      type: Object,
      default: null,
    },
    child: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options: {
      handler() {
        this.getReports();
      },
      deep: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("created-by"),
          value: "userCreatedFullName",
        },
        {
          text: "Date",
          value: "created",
        },
        {
          text: "Doctor",
          value: "doctorFullName",
        },
        {
          text: "Specialty",
          align: "start",
          value: "doctorSpecName",
        },
        {
          text: this.$t("region"),
          align: "start",
          value: "doctorRegionName",
        },
        {
          text: "Products",
          value: "products",
        },
        {
          text: "Visit type",
          value: "visitType",
        },
        {
          text: "Visit with",
          value: "visitWith",
        },
        {
          text: "Visit summary",
          value: "visitSummary",
        },
        {
          text: "Fake protector",
          value: "fake",
        },
      ],
      reports: [],
      loading: false,
      fakeDialog: false,
      fakeMessage: "",
      options: {
        Search: "",
        PageIndex: 1,
        PageSize: 10,
        // CompanyId: this.$store.state.company.id,
        DoctorId: "",
      },
      total: 0,
    };
  },
  methods: {
    showWarings(item) {
      if (item.warings == null) {
        this.$http
          .delete("/DoctorReports/checkFakeReporter /" + item.id)
          .then(() => {
            this.$http.get("/DoctorReports/" + item.id).then((res) => {
              item.warings = res.data.result.warings;
              this.fakeMessage = item.warings;
              this.fakeDialog = true;
              // this.fakeDialog = true;
            });
          });
      } else {
        this.fakeMessage = item.warings;
        this.fakeDialog = true;
      }
    },
    visitWith(item) {
      let visitWith = Object.keys(item).filter((v) => v.match(/visitWith/));
      visitWith = visitWith.map((v) => {
        if (item[v] == true) {
          return v.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          });
        }
      });
      return visitWith;
    },
    getReports() {
      this.loading = true;
      if (this.doctor != null) {
        this.options.DoctorId = this.doctor.id;
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get("/DoctorReports" + filter.replace("?&", "?"))
        .then((prod) => {
          this.total = prod.data.count;
          this.reports = prod.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  created() {
    this.getReports();
  },
};
</script>
