var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('product-batch',{attrs:{"dialog":_vm.productPath},on:{"updated":function (_) {
        _vm.productPath = _;
        _vm.getProducts();
      },"added":function (_) {
        _vm.dialog = _;
        _vm.getProducts();
      }}}),_c('h1',{staticClass:"mb-3 d-flex align-content-center"},[_vm._v(" "+_vm._s(_vm.$t("products"))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-10",attrs:{"large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" "+_vm._s(_vm.$t("product")))],1)],1),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading}},[_c('v-row',[(_vm.$service.hideFrom(function (v) { return [v > 0]; }))?_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t("company")))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"item-text":"name","label":_vm.$t('company'),"item-value":"id","clearable":"","loading":_vm.loading,"no-data-text":_vm.$t('no-data'),"solo":"","hide-details":"auto"},on:{"change":function($event){return _vm.getProducts()}},model:{value:(_vm.options.CompanyId),callback:function ($$v) {_vm.$set(_vm.options, "CompanyId", $$v)},expression:"options.CompanyId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t("warehouse")))]),_c('v-select',{staticClass:"mt-2",attrs:{"clearable":"","items":_vm.warehouses,"search-input":_vm.searchWarehouse,"loading":_vm.loading,"no-data-text":_vm.searchWarehouse == '' && _vm.searchWarehouse == null
              ? 'اكتب للبحث ...'
              : 'لا توجد بيانات',"solo":"","item-value":"id","item-text":"name","hide-details":"auto"},on:{"change":function($event){return _vm.getProducts()},"update:searchInput":function($event){_vm.searchWarehouse=$event},"update:search-input":function($event){_vm.searchWarehouse=$event}},model:{value:(_vm.options.WarehouseId),callback:function ($$v) {_vm.$set(_vm.options, "WarehouseId", $$v)},expression:"options.WarehouseId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t("search")))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1)],1),_c('product',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
          _vm.dialog = _;
          _vm.getProducts();
        }}}),_c('v-data-table',{attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.products,"loading":_vm.loading,"hide-default-footer":"","no-data-text":_vm.$t('no-data')},on:{"click:row":function (_) {
          _vm.$router.push({ name: 'product', params: { id: _.id } });
        }},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.created)))+" ")])]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-1",attrs:{"large":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editProduct(item)}}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")],1)],1)]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }