<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card :loading="loading" outlined>
      <v-card-title class="my-5">
        {{ isEdit ? $t("edit-batches") : $t("add-batches") }}
        <span class="font-weigt-bold mx-1">{{ productName }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading" ref="addProductBatch">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.numberOfBoxes"
                    :label="$t('number-of-boxes')"
                    @input="
                      inputs.numberOfBoxes = formatCurrency(
                        inputs.numberOfBoxes
                      )
                    "
                    placeholder="0.0"
                    :pattern="/^-?\d+\.?\d*$/"
                    outlined
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.numberOfUnitsPerBox"
                    @input="
                      inputs.numberOfUnitsPerBox = formatCurrency(
                        inputs.numberOfUnitsPerBox
                      )
                    "
                    placeholder="0.0"
                    :pattern="/^-?\d+\.?\d*$/"
                    :label="$t('number-of-units')"
                    outlined
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-detials="auto"
                        outlined
                        v-model="inputs.expire"
                        :label="$t('expire-date')"
                        :rules="[(v) => !!v || $t('this-field-is-requird')]"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="inputs.expire"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-detials="auto"
                    v-model="inputs.buyPrice"
                    :label="$t('buy-price')"
                    @input="inputs.buyPrice = formatCurrency(inputs.buyPrice)"
                    placeholder="0.0"
                    :pattern="/^-?\d+\.?\d*$/"
                    suffix="IQD"
                    :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- 
                  <v-col cols="12" md="4">
                    <v-text-field hide-detials="auto"
                      v-model="inputs.wholesaleUnitPrice"
                      label="Wholesale Unit Price"
                      @input="inputs.wholesaleUnitPrice = formatCurrency(inputs.wholesaleUnitPrice)"
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field hide-detials="auto"
                      v-model="inputs.singleBuyPrice"
                      label="Single Buy Price"
                      @input="inputs.singleBuyPrice = formatCurrency(inputs.singleBuyPrice)"
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field hide-detials="auto"
                      v-model="inputs.unitSalePrice"
                      label="Unit Sale Price"
                      @input="inputs.unitSalePrice = formatCurrency(inputs.unitSalePrice)"
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      outlined
                      height="50"
                      label="Notes (Optional)"
                      v-model="inputs.note"
                    ></v-textarea>
                  </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addProductBatch">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatCurrency } from "@/utils";
export default {
  computed: {
    isEdit() {
      return this.$store.state.products.isEdit;
    },
    productName() {
      if (this.$store.state.products.product.name !== undefined) {
        return this.$store.state.products.product.name;
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
      inputs: {
        numberOfBoxes: 0,
        numberOfUnitsPerBox: 0,
        buyPrice: 0,
        expire: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        note: "",
        productId: "",
      },
      //  {
      //     numberOfBoxes: "",
      //     numberOfUnitsPerBox: 0,
      //     expire: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //         .toISOString()
      //         .substr(0, 10),
      //     wholesaleUnitPrice: 0,
      //     unitSalePrice: 0,
      //     singleBuyPrice: 0,
      //     productId: "",
      //     isNew: true,
      //     note: "",
      // }
    };
  },
  created() {},
  mounted() {},
  props: ["dialog"],
  methods: {
    formatCurrency,
    addProductBatch() {
      var val = this.$refs.addProductBatch.validate();

      if (val) {
        this.inputs.productId = this.$route.params.id;
        this.loading = true;
        this.inputs.numberOfBoxes = this.inputs.numberOfBoxes
          .toString()
          .replace(/,/g, "");

        this.inputs.numberOfUnitsPerBox = this.inputs.numberOfUnitsPerBox
          .toString()
          .replace(/,/g, "");

        this.inputs.buyPrice = this.inputs.buyPrice
          .toString()
          .replace(/,/g, "");
        // this.inputs.wholesaleUnitPrice = parseFloat(this.inputs.wholesaleUnitPrice.replace(',',''))
        // this.inputs.unitSalePrice = parseFloat(this.inputs.unitSalePrice.replace(',',''))
        // this.inputs.singleBuyPrice = parseFloat(this.inputs.singleBuyPrice.replace(',',''))
        this.$http
          .post("/ProductBatch/post", this.inputs)
          .then(() => {
            this.dialog = false;
            // this.$router.push({ name: 'accounts-batches', params: { id: this.inputs.productId } })
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    checkIsEdit() {},
  },
  watch: {
    dialog() {
      // this.$refs.addProductBatch.reset();
      if (this.dialog != true) {
        this.$emit("updated", this.dialog);
      } else {
        this.inputs.productId = this.$store.state.products.product.id;
      }
    },
  },
};
</script>
