var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-title',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("edit-batches") : _vm.$t("add-batches"))+" "),_c('span',{staticClass:"font-weigt-bold mx-1"},[_vm._v(_vm._s(_vm.productName))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('f-icon',{attrs:{"icon":"times-circle"}})],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"mt-10"},[_c('v-form',{ref:"addProductBatch",attrs:{"loading":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-detials":"auto","label":_vm.$t('number-of-boxes'),"placeholder":"0.0","pattern":/^-?\d+\.?\d*$/,"outlined":"","rules":[function (v) { return !!v || _vm.$t('this-field-is-requird'); }]},on:{"input":function($event){_vm.inputs.numberOfBoxes = _vm.formatCurrency(
                      _vm.inputs.numberOfBoxes
                    )}},model:{value:(_vm.inputs.numberOfBoxes),callback:function ($$v) {_vm.$set(_vm.inputs, "numberOfBoxes", $$v)},expression:"inputs.numberOfBoxes"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-detials":"auto","placeholder":"0.0","pattern":/^-?\d+\.?\d*$/,"label":_vm.$t('number-of-units'),"outlined":"","rules":[function (v) { return !!v || _vm.$t('this-field-is-requird'); }]},on:{"input":function($event){_vm.inputs.numberOfUnitsPerBox = _vm.formatCurrency(
                      _vm.inputs.numberOfUnitsPerBox
                    )}},model:{value:(_vm.inputs.numberOfUnitsPerBox),callback:function ($$v) {_vm.$set(_vm.inputs, "numberOfUnitsPerBox", $$v)},expression:"inputs.numberOfUnitsPerBox"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-detials":"auto","outlined":"","label":_vm.$t('expire-date'),"rules":[function (v) { return !!v || _vm.$t('this-field-is-requird'); }],"prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.inputs.expire),callback:function ($$v) {_vm.$set(_vm.inputs, "expire", $$v)},expression:"inputs.expire"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{ref:"picker",model:{value:(_vm.inputs.expire),callback:function ($$v) {_vm.$set(_vm.inputs, "expire", $$v)},expression:"inputs.expire"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hide-detials":"auto","label":_vm.$t('buy-price'),"placeholder":"0.0","pattern":/^-?\d+\.?\d*$/,"suffix":"IQD","rules":[function (v) { return !!v || _vm.$t('this-field-is-requird'); }],"outlined":""},on:{"input":function($event){_vm.inputs.buyPrice = _vm.formatCurrency(_vm.inputs.buyPrice)}},model:{value:(_vm.inputs.buyPrice),callback:function ($$v) {_vm.$set(_vm.inputs, "buyPrice", $$v)},expression:"inputs.buyPrice"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex align-center justify-end py-3 px-3"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('f-icon',{attrs:{"icon":"times-circle"}}),_vm._v(" "+_vm._s(_vm.$t('cancel')))],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.addProductBatch}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }